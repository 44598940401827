.new-assignment-wrap {
  .MuiStepper-root {
    margin-bottom: 30px;
    .MuiStep-root {
      height: 56px;
      padding-left: 30px;
      box-shadow: none;
      margin-right: 20px;
      display: flex;
      align-items: center;
      position: relative;
      border: solid 1px #d2d6dc;
      background: #fff;
      border-left: 0;
      border-right: 0;
      box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
      transition: all ease 0.3s;
      &:first-of-type {
        &.stepper-active {
          border-left: solid 1px #7dabf8;
          &::before {
            display: none;
          }
        }
      }
      &:last-of-type {
        &.stepper-active {
          border-right: solid 1px #7dabf8;
          &::after {
            display: none;
          }
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -2px;
        height: 55px;
        width: 20px;
        background: url("../../images/stepper-arrow.svg") no-repeat;
        background-size: cover;
        background-position: center;
      }
      &:after {
        left: initial;
        right: -16px;
        width: 18px;
        top: 0;
      }
      &.stepper-active {
        background: #f5fbff;
        border-top: solid 1px #7dabf8;
        border-bottom: solid 1px #7dabf8;
        &:after {
          background: url("../../images/stepper-arrow-active.svg") no-repeat;
          background-size: cover;
          background-position: center;
          top: 0;
          right: -17px;
        }
        &:before {
          background: url("../../images/stepper-arrow-active-left.svg")
            no-repeat;
          background-size: cover;
          background-position: center;
        }
        .MuiStepLabel-root {
          .MuiStepLabel-labelContainer {
            .MuiStepLabel-label {
              color: #022f74;
              span {
                color: #022f74;
              }
            }
          }
        }
      }
      &.Mui-completed {
        .MuiStepLabel-root {
          .MuiStepLabel-iconContainer {
            display: block;
          }
        }
      }

      .MuiStepConnector-root {
        display: none;
      }
      .MuiStepLabel-root {
        align-items: center;
        flex-direction: row;
        position: relative;
        .MuiStepLabel-iconContainer {
          margin-right: 10px;
          display: none;
          svg {
            color: #07cda5;
          }
        }
        .MuiStepLabel-labelContainer {
          .MuiStepLabel-label {
            text-align: left;
            color: #3c4257;
            font-size: 0.85rem;
            font-weight: 500;
            margin: 0;
            text-transform: capitalize;
            line-height: 1rem;
            transition: all ease 0.3s;
            span {
              font-size: 0.75rem;
              font-weight: 400;
              display: block;
              color: var(--fonts-light);
            }
          }
        }
      }
    }
  }
  .basic-detail {
    .custom-input-wrap {
      margin-bottom: 10px;
      .tagChip {
        font-size: 0.75rem;
        line-height: normal;
        padding-right: 0;
        .MuiChip-deleteIcon {
          margin: 0 0 0 5px;
          color: rgba(35, 65, 122, 0.34);
        }
      }
    }
    .instruction-wrap h2 {
      font-size: 0.75rem;
      font-weight: bold;
      margin-bottom: 10px;
      color: var(--fonts-primary);
    }
    .question-slider-wrap {
      margin-top: 20px;
      > p {
        font-size: 0.85rem;
        font-weight: bold;
        color: var(--fonts-primary);
      }
    }
  }

  .structure-step {
    width: 100%;
    .structure-top-blk {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      h2 {
        font-size: 1rem;
        font-weight: bold;
        color: var(--fonts-primary);
        margin-bottom: 10px;
      }
      span.MuiTypography-caption {
        font-size: 0.75rem;
        font-weight: 400;
        color: var(--fonts-gray);
      }
    }
    .structure-stats {
      margin-bottom: 25px;
      h2 {
        font-size: 0.85rem;
        font-weight: 500;
        text-transform: capitalize;
        color: var(--fonts-primary);
      }
      h3 {
        font-size: 0.75rem;
        font-weight: 400;
        text-transform: capitalize;
        color: var(--fonts-gray);
        // margin: 0 20px 0 0;
      }
    }
    .structure-question-item {
      cursor: move;
      background: #ffffff;
      border: 1px solid rgba(231, 240, 250, 0.84);
      border-radius: 4px;
      padding: 12px 15px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .left-blk {
        margin-right: 20px;
        h2 {
          font-size: 0.75rem;
          text-transform: capitalize;
          color: #96989a;
          span {
            color: #606060;
            font-weight: bold;
          }
        }
        .question-line {
          h2 {
            font-size: 0.85rem;
            color: var(--fonts-primary);
            font-weight: 500;
            line-height: normal;
            margin-top: 5px;
          }
        }
      }
      .right-blk {
        .MuiInputBase-root {
          padding-left: 10px;
          border-radius: 2px;
          height: 36px;
          fieldset {
            border-color: #e9e9e9;
          }
          input {
            font-size: 0.75rem;
            padding: 0;
            margin-left: 8px;
            color: var(--fonts-gray);
            width: 35px;
            &::placeholder {
              color: var(--fonts-gray);
              opacity: 1;
            }
          }
          .MuiInputAdornment-root {
            p {
              font-size: 0.75rem;
              color: var(--fonts-gray);
            }
          }
        }
      }
    }
    .drag-blk {
      background: #f6f9fc;
      border: 2px dashed #e7f0fa;
      border-radius: 4px;
      height: 60px;
      color: #a4b5c9;
      font-size: 0.75rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }
    .section-wrap {
      margin-bottom: 25px;
      .top-blk {
        margin-bottom: 5px;
        h2 {
          font-size: 0.75rem;
          color: var(--fonts-primary);
          font-weight: bold;
        }
        .icon-remove {
          font-size: 0.9rem;
          color: var(--fonts-primary);
          stroke: var(--fonts-primary);
        }
      }
    }
  }
  .student-step {
    width: 100%;
    .student-top-blk {
      margin-bottom: 30px;
      h2 {
        font-size: 1rem;
        font-weight: bold;
        color: var(--fonts-primary);
        margin-bottom: 10px;
      }
      span.MuiTypography-caption {
        font-size: 0.75rem;
        font-weight: 400;
        color: var(--fonts-gray);
      }
    }
    .student-selection {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(226, 226, 226, 0.34);
      border-radius: 4px;
      padding: 15px 10px;
      .MuiCardContent-root {
        padding: 0;
      }
      .info-blk {
        h2 {
          font-size: 0.85rem;
          color: var(--fonts-primary);
          font-weight: 500;
          line-height: 0.85rem;
        }
        span {
          font-size: 0.75rem;
          color: var(--fonts-gray);
          font-weight: 400;
        }
      }
    }
    .students-selection-detail {
      text-align: center;
      margin-top: 30px;
      h2 {
        font-size: 1rem;
        font-weight: bold;
        color: var(--fonts-primary);
        margin-bottom: 10px;
      }
      span.MuiTypography-caption {
        font-size: 0.75rem;
        font-weight: 400;
        color: var(--fonts-gray);
      }
    }
  }
}
.question-step {
  .question-top-blk {
    h2 {
      font-size: 1rem;
      font-weight: bold;
      color: var(--fonts-primary);
      margin-bottom: 10px;
    }
    span.MuiTypography-caption {
      font-size: 0.75rem;
      font-weight: 400;
      color: var(--fonts-gray);
    }
    h3 {
      font-size: 0.85rem;
      font-weight: 500;
      color: var(--fonts-gray);
    }
  }
  .question-filter-blk {
    background: rgba(237, 238, 239, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 12px 20px 20px 20px;
    margin-bottom: 20px;
    .pin-icon {
      margin-right: 5px;
    }
    h2 {
      font-size: 0.9rem;
      font-weight: bold;
      color: var(--fonts-primary);
      margin-bottom: 15px;
    }
    .MuiButton-text {
      font-size: 0.75rem;
      font-weight: 500;
      color: var(--fonts-primary);
      text-transform: capitalize;
    }
  }
  .question-blk {
    background: #ffffff;
    border: 1px solid rgba(171, 193, 216, 0.84);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 20px;
    .question-info {
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      h3 {
        font-size: 0.75rem;
        text-transform: capitalize;
        color: #96989a;
        span {
          color: #606060;
          font-weight: bold;
        }
      }
    }
    .question-line {
      h2 {
        font-size: 0.85rem;
        line-height: normal;
        color: var(--fonts-primary);
        font-weight: 500;
      }
    }
  }
}
