h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0px;
}
html,
body {
  font-size: 16px;
  margin: 0px;
  scroll-behavior: smooth;
  color: var(--font-primary);
  letter-spacing: 0.2px;
}
ul {
  list-style: none;
  padding: 0px;
  a {
    text-decoration: none;
  }
}
table {
  width: 100%;
  border-spacing: 0;
}
.label-value {
  h2 {
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--fonts-gray);
    text-transform: capitalize;
    display: flex;
  }
  h3 {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--fonts-secondary);
    text-transform: capitalize;
  }
}
#webpack-dev-server-client-overlay {
  display: none;
}
.css-11o2879-MuiDrawer-docked + .main-wrapper , .css-nws099 + .main-wrapper {
  margin-left: 64px;
}
// .css-11o2879-MuiDrawer-docked + .main-wrapper .general-btm-blk {
//   width: calc(100% - 64px);
// }
.main-wrapper,
.MuiModal-root {
  .caption {
    font-size: 0.85rem;
    color: var(--fonts-primary);
    margin-bottom: 15px;
    font-weight: bold;
    line-height: normal;
  }
}

.main-wrapper {
  padding-top: 100px;
  width: 100%;
  background: #fdfdfd;
  min-height: 100vh;
  margin-left: 240px;
  .occurrence-blk {
    h3 {
      font-size: .85rem;
      font-weight: bold;
      color: var(--fonts-primary);
      margin-bottom: 15px;
    }
  }
  .slider-inputs {
    input {
      background: #FAFAFA;
      border-radius: 3px;
      color: var(--fonts-primary);
      font-size: .75rem;
      width: 60px;
      height: 24px;
      padding: 0;
      margin-top: 2px;
      text-align: center;
    }
    fieldset {
      border-color:  rgba(193, 193, 193, 0.6);
      border-radius:3px;
    }
  }
  .add-section {
    width: 100%;
    background: rgb(250 250 250);
    border: 2px dashed #e7f0fa;
    border-radius: 4px;
    height: 42px;
    font-size: 0.75rem;
    text-transform: capitalize;
    color: var(--fonts-primary);
    font-weight: bold;
    padding: 0;
    svg {
      margin-right: 5px;
      font-size: 16px;
      color: var(--fonts-primary);
    }
  }
  .heading-main {
    font-size: 1.1rem;
    color: var(--heading-main);
    font-weight: 800;
  }
  .filter-btn {
    padding: 8px 18px 8px 40px;
    color: var(--fonts-primary);
    font-size: 0.85rem;
    background: var(--white);
    border: 0.4px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 500;
    line-height: normal;
    > .MuiButton-startIcon {
      position: absolute;
      left: 10px;
      margin: 0;
    }
  }
  .btn-filter-type {
    padding: 8px 18px 8px 18px;
    color: var(--fonts-primary);
    font-size: 0.85rem;
    background: var(--white);
    border: 0.4px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 500;
    line-height: normal;
  }
  .btn-auto-select {
    box-shadow: none;
    font-size: 0.85rem;
    color: var(--fonts-primary);
    border: 0.4px solid #dbdbdb;

    input {
      font-size: 0.85rem;
      color: var(--fonts-primary);
    }
  }
  .sliderTypeTwo {
    .MuiTypography-root {
      font-size: 0.75rem;
      color: var(--fonts-primary);
      font-weight: bold;
      margin-bottom: 10px;
    }
    .MuiSlider-root {
      .MuiSlider-thumb {
        width: 15px;
        height: 15px;
        border: solid 4px #fff;
      }
      .MuiSlider-rail {
        color: #dadee5;
        opacity: 1;
      }
      .MuiSlider-track {
        color: #0a75f5;
      }
      .MuiSlider-mark {
        display: none;
      }
      .MuiSlider-markLabel[data-index="0"] {
        left: 5px !important;
      }
      .MuiSlider-markLabel[data-index="1"] {
        right: -5px !important;
        left: initial !important;
      }
      .MuiSlider-markLabel {
        font-weight: 400;
        color: #414b5f;
        font-size: 0.65rem;
      }
    }
  }
  .question-slider-wrap {
    .MuiSlider-root {
      color: transparent;
      .MuiSlider-thumb {
        &:before {
          display: none;
        }
        &:hover {
          box-shadow: none;
        }
      }
      .MuiSlider-markLabel {
        font-size: 0.65rem;
        font-weight: 500;
        color: var(--fonts-primary);
      }
      .MuiSlider-valueLabel {
        display: none;
      }
    }
  }

  .MuiDivider-root {
    border-color: rgba(132, 134, 137, 0.2);
    width: 100%;
  }
  .MuiChip-root {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.7rem;
    line-height: normal;
    font-weight: 500;
    text-transform: capitalize;
    border: 0;
    height: auto;
    > span {
      padding: 0;
    }
    &.levelChip {
      background: rgba(34, 196, 97, 0.1);
      color: #22c461;
    }
    &.Easy {
      background: rgba(34, 196, 97, 0.1);
      color: #22c461;
    }
    &.Medium {
      background: rgba(211, 205, 92, 0.3);
      color: #b4af4b;
    }
    &.Hard {
      background: rgba(255, 161, 76, 0.1);
      color: #ffa14c;
    }
    &.Hardest {
      background: rgba(245, 96, 62, 0.1);
      color: #f5603e;
    }
    &.Inferno {
      background: rgba(220, 26, 46, 0.1);
      color: #dc1a2e;
    }
    &.tagChip {
      background: rgba(10, 117, 245, 0.1);
      color: var(--fonts-primary);
    }
    &.lectureChip {
      background: #F5F5F5;
      color: #788093;
      border-radius: 20px;
      padding: 4px 10px;
    }
    &.questionTopicChip {
      background: #e6f0fe;
      color: #233548;
      border-radius: 6px;
      padding: 4px 10px;
    }
    &.iconChip {
      background: var(--btn-primary-bg);
      color: var(--white);
      border-radius: 24px;
      padding: 5px 16px 5px 36px;
      position: relative;
      svg {
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--white);
        margin: 0 !important;
        font-size: 1rem;
      }
    }
  }
  .sub-heading {
    font-size: 0.75rem;
    color: var(--fonts-primary);
    margin-bottom: 15px;
    font-weight: 500;
    line-height: normal;
  }
}
.btn-primary.MuiButton-root {
  background: var(--btn-primary-bg);
  height: 48px;
  line-height: 48px;
  padding: 0 60px;
  color: #fff !important;
  letter-spacing: 0.2px !important;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  display: inline-block;
  transition: all ease 0.4s;
  &.small {
    height: 36px;
    line-height: 36px;
    padding: 0 30px;
    font-size: .85rem;
    font-weight: 500;
  }
  &.p40 {
    padding: 0 40px;
  }
  &:hover {
    background: var(--btn-primary-bg-hover);
  }
  &:disabled {
    opacity: 0.5;
  }
}
.btn-secondary.MuiButton-root {
  background: #fff;
  height: 48px;
  line-height: 48px;
  padding: 0 60px;
  color: var(--fonts-primary);
  letter-spacing: 0.2;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  display: inline-block;
  box-shadow: 0px 0px 26px rgba(189, 189, 189, 0.25);
}
.btn-text.MuiButton-root {
  color: var(--btn-primary-bg);
  font-weight: bold;
  font-size: 0.7rem;
  line-height: normal;
  .MuiButton-startIcon {
    margin: 0;
    > span {
      font-size: 0.8rem;
    }
    svg {
      font-size: 14px;
    }
  }
}
.css-1xq3l18 {
  margin: 0;
}
.custom-cards-wrap {
  display: flex;
  flex-wrap: wrap;
  
  .custom-cards {
    background: var(--white);
    box-shadow: 0px 1px 24px 1px rgba(239, 239, 239, 0.29);
    border-radius: 10px;
    padding: 24px 24px 0 24px;
    width: 335px;
    margin-right: 20px;
    cursor: pointer;
    &.secondary {
      box-shadow: 0px 8px 24px 1px #E9EBFF;
      width: 100%;
      margin: 0;
      padding: 20px 20px 0 20px;
      h2 {
        margin-bottom: 8px;
        font-size: 1rem;
        font-weight: 800;
      }
      h3 {
        font-size: .75rem;
        color: #788093;
      }
    }
    .MuiCardContent-root {
      padding: 0 !important;
    }
    .top-icons {
      display: flex;
      justify-content: space-between;

      .progress-blk {
        background: rgba(193, 193, 193, 0.09);
        border-radius: 3px;
        height: 42px;
        width: 42px;
        line-height: normal;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-size: 0.75rem;
          font-weight: bold;
          color: #414b5f;
          margin-bottom: 0;
        }
        h3 {
          font-size: 0.65rem;
          font-weight: normal;
          color: #167c6e;
          margin-bottom: 0;
        }
      }
    }
    .card-icon {
      font-size: 2rem;
      color: var(--fonts-primary);
      margin-bottom: 12px;
      display: block;
    }
    h2 {
      color: var(--fonts-primary);
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 20px;
      text-transform: capitalize;
    }
    .card-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      h3 {
        width: 50%;
        &:nth-child(even) {
          text-align: right;
        }
      }
    }
    h3 {
      color: var(--fonts-secondary);
      font-weight: 400;
      font-size: 0.85rem;
      text-transform: capitalize;
      margin-bottom: 20px;
      span {
        margin-right: 5px;
      }
    }
  }
}

.dropdownMenu .MuiPaper-root {
  padding: 15px 24px;
  box-shadow: 0px 0px 26px rgb(189 189 189 / 25%);
  border-radius: 4px;
  .dropdownMenuTitle {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--fonts-primary);
    margin-bottom: 20px;
  }
  .MuiFormGroup-root {
    label {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 25px;
      }
      .MuiTypography-root {
        font-size: 0.75rem;
        color: var(--fonts-primary);
        font-weight: 500;
        letter-spacing: 0.2px;
        position: relative;
        top: 1px;
      }
      .MuiCheckbox-root {
        padding: 5px;
        svg {
          fill: var(--fonts-primary);
        }
      }
    }
  }
}
.SidebarDialog {
  &.big-popup {
    .MuiDialog-container {
      .MuiPaper-root {
        width: 900px;
      }
    }
  }
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.24);
  }
  .MuiDialog-container {
    justify-content: flex-end;
    .MuiPaper-root {
      width: 650px;
      box-shadow: none;
      .MuiDialogTitle-root {
        height: 85px;
        line-height: 85px;
        padding: 0 0 0 65px;
        background: #f4f9ff;
        color: var(--fonts-primary);
        font-weight: bold;
        font-size: 1.1rem;
        position: relative;
        .MuiIconButton-root {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          color: var(--fonts-primary);
        }
      }
      .MuiDialogContent-root {
        padding: 20px 10px 10px 24px;
      }
    }
  }
}
.custom-input-wrap {
  &.select-box {
    .MuiInputBase-root {
      width: 100%;
      .MuiSvgIcon-root {
        padding-right: 5px;
      }
      .MuiSelect-select {
        background: #fefeff;
        border-radius: 4px;
        font-size: 0.75rem;
        color: var(--fonts-primary);
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #e9e9e9 !important;
      }
    }
  }
  &.bg-light {
    .MuiFormControl-root {
      .MuiInputBase-root {
        background: #fefeff;
      }
    }
  }
  &.custom-date-picker {
    .MuiFormControl-root {
      .MuiInputBase-root {
        .MuiButtonBase-root {
          color: var(--fonts-primary);
          .MuiSvgIcon-root {
            font-size: 0.6rem;
          }
        }
        input {
          &::placeholder {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .MuiCheckbox-root {
    &.Mui-checked {
      .MuiSvgIcon-root {
        color: #2ec383;
      }
    }
    .MuiSvgIcon-root {
      color: #e9e9e9;
      font-size: 1.3rem;
    }
  }
  .MuiTypography-root {
    color: var(--fonts-primary);
    line-height: 2rem;
    font-size: 0.75rem;
    font-weight: bold;
  }
  .MuiFormControl-root {
    width: 100%;
    .MuiInputBase-root {
      height: 40px;
      background: #f6f7f9;
      fieldset {
        border: 1px solid #e9e9e9;
      }
      input {
        padding: 0 0 0 10px;
        font-size: 0.75rem;
        line-height: normal;
        &::placeholder {
          color: rgba(36, 53, 72, 0.4);
        }
      }
    }
  }
  .icon-send {
    color: #0a75f5;
    transform: rotate(-45deg);
    font-size: 1rem;
  }
}
.custom-grid {
  .ag-header-cell {
    padding: 0 12px 0 12px;
    .ag-cell-label-container {
      .ag-header-cell-label {
        .ag-header-cell-text {
          color: #00072b;
          font-size: 0.85rem;
          font-weight: 500;
        }
      }
    }
  }
  .ag-row {
    border: 0;
    .ag-cell {
      color: #00072b;
      font-size: 0.85rem;
      font-weight: 400;
      box-shadow: inset 0px -1px 0px #dfe2e9;
      padding: 0 12px;
    }
  }
  .ag-paging-panel {
    border: 0;
    background: #e6f1ff;
    height: 45px;
    color: #00072b;
  }
}
.custom-border {
  height: 1px;
  margin-bottom: 30px;
  width: 100%;
  background: url("../../images/border.png");
}
.video-drag-blk {
  height: 290px;
  display: flex;
  align-items: center;
  background: #FAFAFA;
  flex-direction: column;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%235E5E5E80' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 9px;
  margin-bottom: 20px;
  justify-content: center;
  .MuiSvgIcon-root {
    color: #5E5E5E;
    font-size: 5rem;
    margin-bottom: 24px;
  }
  h2 {
    font-size: .85rem;
    font-weight: bold;
    color: var(--fonts-primary);
    span {
      color:#0A75F5;
    }
  }
  h3 {
    font-size: .75rem;
    font-weight: 400;
    color: var(--fonts-primary);
    
  }
}
.general-btm-blk {
  padding: 30px 24px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(6px);
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: solid 1px rgba(247, 244, 244, 0.85);
  z-index: 99;
}
.custom-editor {
  &.rdw-editor-wrapper {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    .rdw-editor-toolbar {
      padding: 0;
      border: 0;
      border-bottom: 1px solid #f2f2f2;
      .rdw-option-wrapper,
      .rdw-dropdown-wrapper {
        box-shadow: none !important;
        border: 0;
        border-radius: 0;
        padding: 0;
        margin: 12px;
      }
    }
    .rdw-editor-main {
      min-height: 120px;
      .public-DraftStyleDefault-block {
        margin: 10px 15px;
        span[data-text] {
          color: var(--fonts-primary) !important;
        }
      }
    }
  }
}
.fullscreen-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(255,255,255, 0.7);
}
.calender-popover {
  z-index: 999;
  position: relative;
  &:after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
  .events-wrap {
    width: 345px;
    max-height: 245px;
    overflow: auto;
    background: #fff;
    border-radius: 8px;
    padding: 16px 24px 24px 24px;
    box-shadow: 0px 6px 26px rgba(178, 204, 234, 0.71);
    position: relative;
    
    .event .date {
      color: var(--fonts-primary);
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 16px;
    }
    
  }
}