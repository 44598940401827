.css-1sir14i-MuiPaper-root-MuiAppBar-root + .MuiDrawer-root , .css-oe4oql + .MuiDrawer-root {
  .MuiDrawer-paper {
    .app-logo {
      opacity: 1;
    }
    .MuiList-root {
      .MuiListItemButton-root {
        padding: 12px 0 12px 30px;
        position: relative;
        transition: all ease 0.3s;
        .MuiListItemText-root {
          display: block;
        }
      }
    }
  }
}
.MuiDrawer-root {
  position: fixed;
  z-index: 999;
}
.MuiDrawer-paper {
  padding: 30px 0 0 0;
  border-right: 1px solid rgba(231, 240, 250, 0.5) !important;
  .app-logo {
    max-width: 96px;
    margin: 0 auto 100px auto;
    display: block;
    transition: all ease 0.4s;
    opacity: 0;
  }
  .MuiList-root {
    .MuiListItemButton-root {
      padding: 12px;
      margin-bottom: 15px;
      justify-content: center;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 4px;
        height: 0%;
        top: 50%;
        transition: all ease 0.5s;
        background: #23417a;
        border-radius: 0px 1px 1px 0px;
        transform: translateY(-50%);
      }
      &:hover,
      &.route-active {
        background: rgba(35, 65, 122, 0.1);
        &:before {
          height: 100%;
        }
        .MuiListItemText-root,
        .sidebar-icon {
          color: #23417a;
        }
      }
      .sidebar-icon {
        font-size: 1.5rem;
        color: var(--fonts-secondary);
        transition: all ease 0.3s;
      }
      .MuiListItemIcon-root {
        min-width: auto;
      }
      .MuiListItemText-root {
        color: var(--fonts-secondary);
        margin: 0 0 0 20px;
        display: none;
        transition: all ease 0.3s;
      }
    }
  }
}
