.m0 {
  margin: 0 !important;
}
.pr-15 {
  padding-right: 15px;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.text-bold {
  font-weight: 600;
}
.text-medium {
  font-weight: 500;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-red {
  color: #ef0909 !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-center-corner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vertical-middle {
  display: flex;
  align-items: center;
}
.align-right {
  justify-content: flex-end;
}
.absolute-center {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.absolute-center-top {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.border0 {
  border: none;
}
.w-100 {
  width: 100%;
}
.p24 {
  padding-left: 24px;
  padding-right: 24px;
}
.d-row {
  display: flex;
  flex-direction: row;
}
.d-col {
  display: flex;
  flex-direction: column;
}
.d-flex {
  display: flex;
}
