.leacture-wrap {
    background: var(--white);
    box-shadow: 0px 3px 12px 1px #E9EBFF;
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 16px;
    cursor: move;
    .count {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: var(--gray-secondary);
        border: solid 1px var(--gray-secondary);
        margin-right: 26px;
        span {
            font-size: .65rem;
            line-height: 20px;
        }
    }
    h2 {
        font-size: 1rem;
        font-weight: bold;
        color: var(--heading-main);
    }
    .btm-blk {
        svg {
            font-size: 1.2rem;
            color: var(--gray-secondary);
            margin-right: 26px;
            vertical-align: middle;
        }
    }
}
.lecture-drag-blk {
    background: #E6F1FF;
    border-radius: 6px;
    height: 56px;
    padding: 0 20px;
    h2 {
        font-weight: 400;
        font-size: .75rem;
        color: var(--btn-primary-bg);
    }
}
.lecture-view-detail {
    background: #fff;
    border-radius: 0px 0px 4px 4px;
    .tab-content {
        p {
            font-size: .85rem;
            color: #788093;
            line-height: 20px;
        }
        h2 {
            font-size: 1.1rem;
            color: var(--fonts-primary);
            font-weight: 800;
            margin-bottom: 10px;
        }
        h3 {
            font-size: .85rem;
            color: var(--fonts-primary);
            font-weight: 800;
            margin-bottom: 10px;
        }
    }
}
audio {
    width: 100% !important;
}
.download-anchor {
    position: absolute;
    top: 14px;
    right: 15px;
    color: var(--btn-primary-bg);
}