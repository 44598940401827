.filter-top-wrap {
  margin-bottom: 48px;
  .breadcrumb-wrap {
    h2.header-title {
      font-size: 2rem;
      font-weight: bold;
      color: var(--fonts-primary);
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    nav {
      ol {
        li {
          font-size: 0.7rem;
          letter-spacing: 0.2px;
          text-transform: capitalize;
          color: var(--fonts-secondary);
          margin: 0;
          &:last-of-type {
            color: #23417a;
          }
        }
      }
    }
  }
}
