:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --fonts-primary: #233548;
  --fonts-secondary: #5e5e5e;
  --fonts-light: #989aa2;
  --fonts-gray: #848689;
  --gray-secondary:#B9BCC1;
  --heading-main:#414B5F;
  --btn-primary-bg: #23417a;
  --btn-primary-bg-hover: #375a9f;
}
