@font-face {
    font-family: 'Avenir-Regular';
    src:url('Avenir-Regular.woff') format('woff'),
        url('Avenir-Regular.woff2') format('woff2'),
        url('Avenir-Regular.eot'),
        url('Avenir-Regular.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Regular.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}