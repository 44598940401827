header {
  border-bottom: 1px solid rgba(231, 240, 250, 0.5);
  .MuiIconButton-root {
    color: #5d5d5d;
  }
  .MuiBox-root {
    button {
      color: black;
    }
    .MuiButton-root {
      background: rgba(247, 252, 254, 0.3);
      height: 64px;
      border-left: 1px solid rgba(231, 240, 250, 0.5);
      > .icon-grade {
        display: block;
        margin: -2px 8px 0 0;
        font-size: 1.3rem;
      }
    }
    .MuiIconButton-root:nth-child(2) {
      background: rgba(88, 167, 133, 0.08);
      border-radius: 0;
      padding: 0;
      height: 64px;
      width: 68px;
      margin: 0;
    }
  }
}
