@use "./base/variables";
@use "./base/grid";
@use "./base/utils";
@use "./base/common";
@use "./base/header";
@use "./base/sidebar";
@use "./component-styles/dashboard";
@use "./component-styles/calender";
@use "./component-styles/calenderListings";
@use "./component-styles/tree";
@use "./base/breadcrumb-filter";
@use "./component-styles/new-assignment";
@use "./component-styles/categoryDetailsDialog";
@use "./component-styles/question-detail";
@use "./component-styles/new-question";
@use "./component-styles/assignment-preview";
@use "./component-styles/tabs-secondary";
@use "./component-styles/courses";
@use "./component-styles/lectures";
@use "./component-styles/gpt-questions";
@use "./base/tables";
@use "./component-styles/student-details";
@use "./component-styles/login";
@import "react-big-calendar/lib/sass/styles";

.MuiTreeItem-content {
  background-image: url("../images/cat-sidebar-shape.png");
}
