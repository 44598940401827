.cat-details-dialog {
  ul {
    li.last-child {
      .MuiTreeItem-content {
        &::after {
          display: none;
        }
      }
    }
  }
  .MuiTreeView-root {
    ul {
      .icon-square {
        color: #f77320;
      }
      ul {
        .icon-square {
          color: #7146f7;
        }
        ul {
          .icon-square-wrap {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              width: 5px;
              height: 5px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: #ffffff;
              border-radius: 50%;
            }
          }
          .icon-square {
            color: #f00af5;
          }
        }
      }
    }
    > .MuiTreeItem-root {
      display: flex;
      flex-direction: column;
      ul {
        padding: 5px 10px 5px 25px;
      }

      .MuiTreeItem-content {
        padding-left: 0px;
        background: transparent;
        height: 42px;
        transition: all ease 0.4s;
        border: 1px solid transparent;
        position: relative;
        pointer-events: none;
        &::after {
          content: "";
          position: absolute;
          bottom: -28px;
          left: 20px;
          width: 13px;
          height: 35px;
          background-image: url("../../images/cat-sidebar-shape.png");
        }
        &.Mui-expanded {
          .right-blk {
            .tree-actions {
              .arrow {
                transform: rotate(180deg);
              }
            }
          }
        }

        .MuiTypography-root {
          color: var(--fonts-primary);
        }
        .icon-square-wrap {
          margin-right: 5px;
        }
        .MuiTreeItem-iconContainer {
          display: none;
        }
        .right-blk {
          display: flex;
          align-items: center;
          position: relative;
          transition: all ease 0.4s;
          padding-right: 0;
          .tree-actions {
            position: absolute;
            transform: translateY(10px);
            right: 0;
            display: flex;
            opacity: 0;
            transition: all ease 0.3s;
            &.lastChildActions {
              svg {
                font-size: 1.2rem;
              }
            }
            .arrow {
              transition: all ease 0.3s;
            }
          }
        }
      }
      .MuiCollapse-root {
        min-height: auto !important;
      }
    }
  }
  .edit-box {
    background: rgba(219, 235, 255, 0.3);
    border-radius: 7px;
    padding: 25px 10px 10px 10px;
    margin-bottom: 30px;
    .edit {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
      padding-left: 30px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 18px;
        height: 106px;
        background-image: url("../../images/comments-line.svg");
      }
      &:last-of-type {
        margin-bottom: 0;
        &:after {
          display: none;
        }
      }
      h2 {
        text-transform: capitalize;
        font-size: 0.75rem;
        color: var(--fonts-primary);
        margin-bottom: 5px;
        font-weight: 400;
      }
      h3 {
        text-transform: capitalize;
        font-size: 0.75rem;
        color: var(--fonts-secondary);
        margin-bottom: 5px;
        font-weight: 500;
      }
    }
  }
  .comment-box {
    .box {
      background: rgba(246, 247, 249, 0.8);
      border-radius: 6px;
      padding: 10px;
      display: flex;
      align-items: center;
      p {
        font-size: 0.75rem;
        color: var(--fonts-primary);
        font-weight: 500;
      }
      .info-blk {
        display: flex;
        align-items: center;
        h2 {
          font-size: 0.75rem;
          color: var(--fonts-primary);
          text-transform: capitalize;
          margin-right: 8px;
          font-weight: 500;
        }
        h3 {
          font-size: 0.65rem;
          color: var(--fonts-light);
        }
      }
    }
  }
}
