@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4wwdk3');
  src:  url('fonts/icomoon.eot?4wwdk3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4wwdk3') format('truetype'),
    url('fonts/icomoon.woff?4wwdk3') format('woff'),
    url('fonts/icomoon.svg?4wwdk3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-personalized-assignment:before {
  content: "\e919";
  color: #414b5f;
}
.icon-projected-grade:before {
  content: "\e91b";
  color: #414b5f;
}
.icon-improved-score:before {
  content: "\e91c";
  color: #414b5f;
}
.icon-current-score:before {
  content: "\e91d";
  color: #414b5f;
}
.icon-audios:before {
  content: "\e916";
}
.icon-pdf:before {
  content: "\e917";
}
.icon-videos:before {
  content: "\e918";
}
.icon-tair:before {
  content: "\e912";
}
.icon-questions:before {
  content: "\e913";
}
.icon-lecture:before {
  content: "\e914";
}
.icon-lectures:before {
  content: "\e914";
}
.icon-assignment1:before {
  content: "\e915";
}
.icon-students:before {
  content: "\e91a";
}
.icon-art:before {
  content: "\e900";
}
.icon-assignment:before {
  content: "\e901";
}
.icon-assignments:before {
  content: "\e901";
}
.icon-bell:before {
  content: "\e902";
}
.icon-category:before {
  content: "\e903";
}
.icon-computer:before {
  content: "\e904";
}
.icon-course:before {
  content: "\e905";
}
.icon-dashboard:before {
  content: "\e906";
}
.icon-down:before {
  content: "\e907";
}
.icon-drag:before {
  content: "\e908";
}
.icon-filter:before {
  content: "\e909";
}
.icon-grade:before {
  content: "\e90a";
}
.icon-hamburger:before {
  content: "\e90b";
}
.icon-languages:before {
  content: "\e90c";
}
.icon-maths:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-question:before {
  content: "\e90f";
}
.icon-science:before {
  content: "\e910";
}
.icon-students1:before {
  content: "\e911";
}
