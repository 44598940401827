.tabs-secondary {
    border: 1px solid rgba(224, 224, 224, 0.71);
    border-radius: 5px;
    margin-bottom: 32px;
    background: #fff;
    .MuiTab-root {
        padding: 22px 30px;
        font-size: 1rem;
        text-transform: capitalize;
        font-weight: 500;
        color: #414B5F;
        line-height: 22px;
        transition: all ease .3s;
        position: relative;
        &::after {
            position: absolute;
            bottom: 0;
            transition: all ease .3s;
            width: 0;
            left: 0;
            height: 3px;
            background: #414B5F;
            content: '';
        }
        &:hover , &.Mui-selected{
            font-weight: bold;
            color: #414B5F;
            &::after {
                width: 100%;
            }
        }
    }
    .MuiTabs-indicator {
        display: none;
    }
}