.quest-history {
  h6 {
    font-size: 1rem;
    font-weight: 500;
    color: var(--fonts-primary);
    text-transform: capitalize;
  }
}
.MuiTooltip-popper {
  &.custom-tooltip {
    .MuiTooltip-tooltip {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 1px 24px 1px rgba(242, 228, 228, 0.29);
      padding: 14px 5px 10px 0;
      .MuiTreeView-root {
        ul {
          li.last-child {
            .MuiTreeItem-content {
              &::after {
                display: none;
              }
            }
          }
        }
        ul {
          .icon-square {
            color: #f77320;
          }
          ul {
            .icon-square {
              color: #7146f7;
            }
            ul {
              .icon-square-wrap {
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  background: #ffffff;
                  border-radius: 50%;
                }
              }
              .icon-square {
                color: #f00af5;
              }
            }
          }
        }
        > .MuiTreeItem-root {
          display: flex;
          flex-direction: column;
          ul {
            padding-left: 10px;
          }

          .MuiTreeItem-content {
            padding-left: 0px;
            background: transparent;
            height: 20px;
            transition: all ease 0.4s;
            border: 1px solid transparent;
            position: relative;
            pointer-events: none;
            &::after {
              content: "";
              position: absolute;
              bottom: -12px;
              left: 14px;
              width: 13px;
              height: 16px;
              background-image: url("../../images/cat-sidebar-shape.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.Mui-expanded {
              .right-blk {
                .tree-actions {
                  .arrow {
                    transform: rotate(180deg);
                  }
                }
              }
            }

            .MuiTypography-root {
              color: var(--fonts-primary);
              font-size: 0.75rem;
            }
            .icon-square-wrap {
              margin-right: 5px;
              .icon-square {
                width: 13px;
                height: 13px;
              }
            }
            .MuiTreeItem-iconContainer {
              display: none;
            }
            .right-blk {
              display: flex;
              align-items: center;
              position: relative;
              transition: all ease 0.4s;
              padding-right: 0;
              .tree-actions {
                position: absolute;
                transform: translateY(10px);
                right: 0;
                display: flex;
                opacity: 0;
                transition: all ease 0.3s;
                &.lastChildActions {
                  svg {
                    font-size: 1.2rem;
                  }
                }
                .arrow {
                  transition: all ease 0.3s;
                }
              }
            }
          }
          .MuiCollapse-root {
            min-height: auto !important;
          }
        }
      }
    }
  }
}